import { useState,useEffect } from "react"
import API from "../API"
import {months} from '../Log/DayDisplay'
import DropDown from '../Achievements/Dropdown'
// bubble component
// that changes color based upon the status passed in

function Bubble({status}:any) {
    let color = ""
    switch(status) {
        case "Incomplete":
            color = "red"
            break 
        case "Complete":
            color = "green"
            break 
        case "Neutral":
            color = "gray"
            break
        case "Wash":
        default: 
            color = "rgba(27, 31, 34, 0.85)"
    }
    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g color={color}>
        <circle cx="50" cy="50" r="50" fill ="currentColor"/>
        </g>
        </svg>
    )
}
function month2Int(month:string) {
    for (let i = 0; i < months.length; i++) {
        if(month == months[i])  {
            return i + 1
        }
    }
    return 1;
}
// component that queries api and renders the 
// status bubbles
export default function StatusBubbles() {
    let [prog, setProgress] = useState<string[]>([] as string[])
    let currentDate = new Date()
    let currentMonth = months[currentDate.getMonth()]
    let [month, setMonth] = useState<string>(currentMonth)
    let [year, setYear] = useState<number>(currentDate.getFullYear())
    useEffect(function(){
        (async function(){
            let api = new API("get","last_28_status")
            let data:any = await api.query({"month":month2Int(month), "year":year})
            setProgress(data)
            
        })()
    },[month, year])

    let progress = [...prog]
    let bubbles = []
    let bubbly:any[] = []
    let min_days = 28

    // if 28 days are not returned by the api
    // pad the rest with black bubbles
    for (let i = 0,d=progress.length; i < (min_days - d); i++) {
        progress.push("Missing")
    }

    // align the bubbles in 4 rows of 7
    for(let i = 0; i < progress.length; i++) {
        if (i % 7 == 0 && i != 0) {
            bubbles.push(bubbly)
            bubbly = []
        }
        bubbly.push(progress[i])
    }

    bubbles.push(bubbly)
    let years = []
    for (let i = 2025; i <= currentDate.getFullYear(); i++) {
        years.push(i)
    }
    
    return (
        <div>
            <h3 className="major" style={{"margin":"2rem 0 1rem 0","border":"0px solid white","display":"flex","alignItems":"center"}}>
                <DropDown name = "month" selected = {month} update = {setMonth} options = {months} isSmall = {true}/>
                <DropDown name = "year" selected = {year} options = {years} update = {setYear} isSmall = {true}/>
                Progress
            </h3>
            <table>
                {bubbles.map(bubbly =>(
                    <tr>
                        {bubbly.map(status => (<td><Bubble status={status}/></td>))}
                    </tr>
                ))}
            </table>
        </div>
    )
}